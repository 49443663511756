<template>
  <div>
    <div v-if="!nodes.length" class="no-files">{{ $t('FILEBROWSER.NO_FILES_YET') }}</div>
    <div v-for="(node) in fileNodes" :key="node.id" class="is-small file-box">
      <icon class="file-icon" icon="file fa-fw" />
      <div class="file-details">
        <div class="filename" :title="node.filename">{{ node.filename }}</div>
        <div class="details">
          <span class="filesize">{{ node.getFileSize() }}</span>
          <span class="uploaded" v-if="node.updated">{{ formatDate(node.updated, 'PPpp') }}</span>
        </div>
      </div>
      <div class="file-actions">
        <a :href="node.getDownloadUrl()" target="_self">
          <icon icon="file-download" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import formatDate from 'date-fns/format';

export default {
  name: 'fileAttachementsList',
  props: {
    nodes: Array,
  },
  methods: {
    formatDate,
  },
  computed: {
    fileNodes() {
      return this.nodes.filter((node) => (node.mimetype !== 'application/directory'));
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_variables.scss";

.file-box {
  display: flex;
  align-items: center;
  padding: 0.6rem;
  margin: auto -0.6rem;
  border-radius: $radius;
  &:hover {
    background-color: rgba($primary, 0.25);
  }

  .file-icon {
    font-size: 2.4rem;
  }
  .file-details {
    flex: 1 1 auto;
    .filename {
      max-width: 36rem;
      font-size: 1.6rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .details {
      font-size: 1rem;
      line-height: 1;
      > * {
        margin-right: 1.2rem;
      }
    }
  }
}

.no-files {
  padding: 1.2rem;
  text-align: center;
}
</style>
