<template>
  <div class="file-upload-box">
    <div
      v-if="!filesToUpload.length"
      class="no-files"
    >{{ $t('FILEBROWSER.UPLOADER.NO_UPLOADS_YET') }}</div>
    <div
      class="file-to-upload"
      v-for="(file, index) in filesToUpload"
      :key="index"
      ref="fileToUpload"
    >
      <icon class="file-upload-icon" icon="file-upload fa-fw" />
      <icon class="file-upload-icon-complete" icon="spinner fa-spin fa-fw" />
      <div class="file-details">
        <div class="filename">{{ file.name }}</div>
        <div class="details">
          <span>{{ getFileSize(file.size) }}</span>
          <span>{{ formatDate(file.lastModified) }}</span>
        </div>
      </div>
      <div class="file-spacer" />
      <div class="file-close" @click="removeFile(index)">
        <icon icon="times" />
      </div>
    </div>

    <div class="file-drop-box" @drop.prevent="handleDrop($event)" @dragover.prevent>
      <a @click="$refs.fileField.click()">{{ $t('FILEBROWSER.UPLOADER.CLICK_TO_UPLOAD') }}</a>
      <div class="fancy-or">
        <hr />
        <span class="the-actual-or">{{ $t('FILEBROWSER.UPLOADER.OR') }}</span>
        <hr />
      </div>
      <div>{{ $t('FILEBROWSER.UPLOADER.DRAG_FILES_HERE') }}</div>
    </div>
    <input type="file" ref="fileField" multiple @change="handleFiles($event)" />
  </div>
</template>

<script>
import formatDate from 'date-fns/format';
import FileNode from '@/api/filebrowser/node.details';

export default {
  name: 'fileUploader',
  props: {
    parentType: String,
    parentFk: String,

    path: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      filesToUpload: [],
    };
  },
  methods: {
    handleDrop($event) {
      const droppedFiles = $event.dataTransfer.files;
      if (!droppedFiles) return;
      // this tip, convert FileList to array, credit: https://www.smashingmagazine.com/2018/01/drag-drop-file-uploader-vanilla-js/
      [...droppedFiles].forEach((file) => {
        this.filesToUpload.push(file);
      });
    },
    handleFiles($event) {
      [...$event.target.files].forEach((file) => {
        this.filesToUpload.push(file);
      });
      this.$refs.fileField.value = '';
    },
    removeFile(idx) {
      this.filesToUpload.splice(idx, 1);
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      return formatDate(date, 'PPpp');
    },
    getFileSize(origSize) {
      let size = origSize;
      let unit = 'B';
      if (size === null || Number.isNaN(size)) {
        return '';
      }
      if (size > 1024) {
        size /= 1024;
        unit = 'K';
      }
      if (size > 1024) {
        size /= 1024;
        unit = 'M';
      }
      if (size > 1024) {
        size /= 1024;
        unit = 'G';
      }
      return `${size.toFixed(2).toString()} ${unit}`;
    },


    async upload() {
      const updateBar = (currentNode, index) => {
        const $ref = this.$refs.fileToUpload[index];

        if (!$ref) { return false; }
        if ($ref.classList.contains('error')) { $ref.style.background = null; return false; }

        $ref.style.background = `linear-gradient(to right, rgb(72, 199, 116), rgb(72, 199, 116) ${currentNode.progress}%, rgba(72, 199, 116 ,0) ${currentNode.progress + 10}% ), rgba(0, 0, 0, 0.5)`;

        if (currentNode.progress < 100) { requestAnimationFrame(updateBar.bind(this, currentNode, index)); }
        if (currentNode.progress >= 100) { $ref.classList.add('complete'); }
        return true;
      };


      for (let idx = 0; idx < this.filesToUpload.length; idx++) {
        const fileToUpload = this.filesToUpload[idx];
        const node = new FileNode();
        node.parent_fk = this.parentFk;
        node.parent_type = this.parentType;
        node.path = this.path;

        requestAnimationFrame(updateBar.bind(this, node, idx));

        // eslint-disable-next-line no-await-in-loop
        await node.saveToServer({ file: fileToUpload }).then(() => {
          this.$refs.fileToUpload[idx].classList.remove('complete');
          fileToUpload.complete = true;
        }).catch(() => {
          this.$refs.fileToUpload[idx].style.background = null;
          this.$refs.fileToUpload[idx].classList.add('error');
        });
      }

      this.filesToUpload = this.filesToUpload.filter((item, idx) => this.$refs.fileToUpload[idx].classList.contains('error'));
      if (Array.isArray(this.$refs.fileToUpload)) {
        this.$refs.fileToUpload.forEach((item, idx) => {
          this.$refs.fileToUpload[idx].style.background = null;
          this.$refs.fileToUpload[idx].classList.add('error');
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_variables.scss";

input[type="file"] {
  display: none;
}

.no-files {
  padding: 2.4rem 0.6rem;
  margin: 2.4rem 0;
  border-radius: $radius;
  font-size: 1.6rem;
  text-align: center;
  color: #eee;
  background-color: rgba(0, 0, 0, 0.5);
}

.file-to-upload {
  padding: 0.6rem;
  padding-right: 1rem;
  margin-bottom: 1.2rem;
  border-radius: $radius;
  color: #eee;
  background-color: rgba(0, 0, 0, 0.5);
  &:hover {
    background-color: rgba(0, 0, 0, 0.75);
  }
  &.error {
    background-color: rgba($danger, 0.75);
  }
  overflow: hidden;

  position: relative;
  display: flex;
  align-items: center;

  .filename {
    max-width: 36rem;
    font-size: 1.4rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-right: 1.2rem;
  }
  .file-upload-icon {
    font-size: 2.4rem;
  }
  .file-upload-icon-complete {
    font-size: 2.4rem;
    display: none;
  }
  &.complete {
    .file-upload-icon { display: none;}
    .file-upload-icon-complete { display: inline-block; }
  }
  .file-details {
    flex: 1 1 auto;
    .filename {
      font-size: 1.6rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .details {
      font-size: 1rem;
      line-height: 1;
      > * {
        margin-right: 1.2rem;
      }
    }
  }
  .file-spacer {
    flex: 1 1 auto;
  }
  .file-close {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    text-align: center;
    height: 2.4rem;
    width: 2.4rem;
    border-radius: $radius;
    background-color: rgba(255, 255, 255, 0.25);
  }

  .file-progress {
    position: absolute;
    top: 0;
    right: 50%;
    width: 110%;
    height: 100%;
    background: linear-gradient(
      to right,
      $success,
      $success 90%,
      rgba($success, 0) 100%
    );
  }
}

.file-drop-box {
  width: 100%;
  padding: 2.4rem;
  border: 1px dashed rgba($primary, 0.5);
  font-size: 1.6rem;
  text-align: center;
  display: flex;
  align-items: center;

  .fancy-or {
    flex: 1 1 auto;
    margin: 0 1.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    .the-actual-or {
      margin: 1.2rem;
    }
    hr {
      flex: 1 1 auto;
    }
  }
}
</style>
