<template>
  <div>
    <slate-loading v-if="loading" />
    <file-list v-else :nodes="nodes.list" />
  </div>
</template>

<script>
import FileNode from '@/api/filebrowser/node.details';
import FileNodeList from '@/api/filebrowser/node.list';

import FileList from './filebrowser.list.vue';

export default {
  name: 'fileAttachements',
  components: { FileList },
  props: {
    parentType: String,
    parentFk: String,

    path: {
      type: String,
      default: '/',
    },
  },
  data() {
    return {
      loading: true,
      nodes: new FileNodeList(),
      filesToUpload: [],
      newNode: new FileNode(),
    };
  },
  watch: {
    parentFk: {
      handler: 'updateData',
      immediate: true,
    },
  },
  methods: {
    updateData() {
      if (!this.parentType || !this.parentFk) {
        return Promise.reject(new Error('Must set parent-type and parent-fk'));
      }
      this.loading = true;

      this.nodes.getList({
        parent_type: this.parentType,
        parent_fk: this.parentFk,
        path: this.path,
      }).then(() => { this.loading = false; });

      return this;
    },
  },
};
</script>
