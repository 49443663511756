import axios from 'axios';
import { cloneDeep } from 'lodash';

import SlateAuth from '@/api/auth/auth';

class BaseApiDetails {
  // PUBLIC required fields. This must be set
  fields;
  url;

  // PUBLIC optional fields
  anonymous = false;

  // PRIVATE fields, dont override any below this point
  id;
  permissions = {};
  progress = 0;
  apiBaseUrl = process.env.VUE_APP_API_URL ? process.env.VUE_APP_API_URL : '/api/v1/';

  constructor(id) {
    if (typeof id === 'string') { this.id = id; }
    this.clear();
  }

  check() {
    if (!(this.fields instanceof Array)) {
      throw new Error('Models must have fields set!!!');
    }
    if (typeof this.url === 'string') {
      if (this.apiBaseUrl.endsWith('/') && this.url.startsWith('/')) {
        this.url = this.url.slice(1);
      }
      if (!this.apiBaseUrl.endsWith('/') && !this.url.startsWith('/')) {
        this.url = `/${this.url}`;
      }
      if (!this.url.endsWith('/')) { this.url = `${this.url}/`; }
    }
  }

  clear() {
    if (this.fields instanceof Array) {
      this.fields.forEach((field) => {
        if (field.def) {
          this[field.name] = cloneDeep(field.def);
        } else if (field.type === 'related') {
          this[field.name] = {};
        } else if (field.type === 'child_list') {
          this[field.name] = [];
        }
      });
    }
    return this;
  }

  isAuthed() {
    if (this.anonymous) { return true; }
    if (SlateAuth.token) { return true; }
    return false;
  }

  loadFromPayload(payload) {
    if (!(payload instanceof Object) || payload instanceof Array) {
      throw new Error('Payload must be an object');
    }

    this.fields.forEach((field) => {
      const fieldType = field.type ? field.type : 'string';

      if (typeof this.parsers[fieldType] !== 'function') {
        throw new Error(`field ${fieldType} is not an available parser`);
      }
      if (field.name in payload) {
        if (typeof payload[field.name] !== 'undefined' && payload[field.name] !== null) {
          this[field.name] = this.parsers[fieldType](cloneDeep(payload[field.name]), field);
        } else {
          this[field.name] = payload[field.name];
        }
      }
    });

    return this;
  }

  parsers = {
    string: (item) => item,
    float: (item) => {
      const num = parseFloat(item);
      return Number.isNaN(num) ? null : num;
    },
    date: (item) => {
      const parts = item.split('-');
      // Month parameter in new Date is an off by one trap
      return new Date(parseInt(parts[0], 10), parseInt(parts[1], 10) - 1, parseInt(parts[2], 10), 0, 0, 0);
    },
    dateTime: (item) => new Date(item),
    json: (item) => JSON.parse(item),
    related: (item, field) => {
      const Model = field.model; // This is solely to keep eslint happy
      const obj = new Model();
      obj.loadFromPayload(item);
      return obj;
    },
    child_list: (item, field) => {
      const newList = [];
      item.forEach((child) => {
        const Model = field.model; // This is solely to keep eslint happy
        const obj = new Model();
        newList.push(obj.loadFromPayload(child));
      });
      return newList;
    },
  };

  getAPIUrl() {
    let url = `${this.apiBaseUrl}${this.url}`;
    if (this.id) {
      url = `${url}${this.id}/`;
    }
    return url;
  }

  loadFromServer(loadId) {
    this.check();
    return new Promise((resolve, reject) => {
      let params = {};

      if (!loadId && !this.id) { return reject(new Error('No id has been set to load. Call loadFromServer with an id, or when instantiating model')); }

      if (!this.isAuthed()) { return reject(new Error('Not logged in')); }
      if (typeof this.url === 'undefined') { return reject(new Error('model url not set')); }
      if (!(this.fields instanceof Array)) { return reject(new Error('Models must have fields set!!!')); }

      if (typeof loadId === 'string') { this.id = loadId; }
      if (typeof loadId === 'object' && !(loadId instanceof Array)) { params = loadId; }

      axios.get(this.getAPIUrl(), {
        params,
      }).then((response) => {
        this.loadFromPayload(response.data.payload);
        if (response.data.permissions) {
          this.permissions = response.data.permissions;
        }
        resolve(this);
      }).catch((error) => {
        reject(error);
      });

      return this;
    });
  }

  getData() {
    const payload = {};
    this.fields.forEach((field) => {
      const fieldType = field.type ? field.type : 'string';

      if (field.readonly && field.name !== 'id') { return false; }
      if (typeof (this[field.name]) === 'undefined') { return false; }

      if (typeof this.serializers[fieldType] !== 'function') {
        throw new Error(`field ${fieldType} is not an available serializer`);
      }

      if (field.name in this) {
        if (typeof this[field.name] !== 'undefined' && this[field.name] !== null) {
          payload[field.name] = cloneDeep(this.serializers[fieldType](this[field.name], field));
        }
      }
      return true;
    });
    return payload;
  }

  serializers = {
    string: (item) => item,
    float: (item) => item,
    date: (item) => `${item.getFullYear()}-${item.getMonth() + 1}-${item.getDate()}`,
    dateTime: (item) => item.toISOString(),
    json: (item) => JSON.stringify(item),
    related: (item) => item.getData(),
    child_list: (item) => item.map((child) => child.getData()),
  };

  // eslint-disable-next-line class-methods-use-this
  savePostCheck(additionalData) { return additionalData; }
  // eslint-disable-next-line class-methods-use-this
  savePreDataProcessing(data, additionalData = {}) { return { data, additionalData }; }
  // eslint-disable-next-line class-methods-use-this
  savePreTransmit(data, axiosOptions) { return { data, axiosOptions }; }

  saveToServer(additionalDataArg = {}) {
    this.check();
    let additionalData = this.savePostCheck(additionalDataArg);
    return new Promise((resolve, reject) => {
      if (!this.isAuthed()) { return reject(new Error('Not logged in')); }
      if (typeof this.url === 'undefined') { return reject(new Error('model url not set')); }
      if (!(this.fields instanceof Array)) { return reject(new Error('Models must have fields set!!!')); }
      this.progress = 0;

      let axiosOptions = {
        onUploadProgress: ($event) => { if ($event.lengthComputable) { this.progress = Math.round(($event.loaded / $event.total) * 100); } },
      };
      const multipart = Object.keys(additionalData).some((key) => (additionalData[key] instanceof File) || (additionalData[key] instanceof Blob));
      let data = this.getData();

      ({ data, additionalData } = this.savePreDataProcessing(data, additionalData));
      if (multipart) {
        const formData = new FormData();
        Object.keys(data).forEach((key) => {
          formData.set(key, data[key]);
        });
        Object.keys(additionalData).forEach((key) => {
          formData.set(key, additionalData[key]);
        });
        data = formData;
        axiosOptions.headers = {
          'Content-Type': 'multipart/form-data',
        };
      }

      ({ data, axiosOptions } = this.savePreTransmit(data, axiosOptions));

      if (this.cancelToken) {
        this.cancelToken.cancel('Re-Saving');
      }
      this.cancelToken = axios.CancelToken.source();
      axiosOptions.cancelToken = this.cancelToken.token;

      axios.post(this.getAPIUrl(), data, axiosOptions).then((response) => {
        this.progress = 100;
        this.loadFromPayload(response.data.payload);
        if (response.data.permissions) {
          this.permissions = response.data.permissions;
        }
        resolve(this);
      }).catch((error) => {
        if (axios.isCancel(error)) {
          error.cancelled = true;
        }

        reject(error);
      });

      return this;
    });
  }
}

export default BaseApiDetails;
