<template>
  <div class="discussion-box">
      <discussion-messages :messages="messages" />
      <discussion-entry :entry="entry" :posting="posting" @post="$emit('post', $event)" />
  </div>
</template>

<script>
import DiscussionMessages from './discussion.msgs.vue';
import DiscussionEntry from './discussion.entry.vue';

export default {
  name: 'slateDiscussionEmbeddedBox',
  components: {
    DiscussionMessages,
    DiscussionEntry,
  },
  props: {
    discussion: Object,
    messages: Array,
    entry: Object,
    posting: {
      type: Boolean,
      default: false,
    },
  },

};
</script>

<style lang="scss" scoped>
  .discussion-box {
    width: 100%;
    min-width: 4.8rem;
    display: flex;
    flex-direction: column;
  }
</style>
