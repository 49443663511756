import SlateAuth from '@/api/auth/auth';
import BaseApiDetails from '../baseApiDetailsClass';


export default class FileNodeDetails extends BaseApiDetails {
  fields = [
    { name: 'id', readonly: true },
    { name: 'slug', readonly: true },
    { name: 'path', def: '' },

    { name: 'parent', readonly: true },
    { name: 'parent_slug', readonly: true },
    { name: 'parent_type', def: null },
    { name: 'parent_fk', def: null },
    { name: 'company_fk', readonly: true },

    { name: 'core', readonly: true },
    { name: 'core_id' },
    { name: 'version', readonly: true },
    { name: 'total_child_nodes', readonly: true },

    { name: 'mimetype', def: 'application/octet-stream' },
    { name: 'filename', def: 'Untitled' },
    { name: 'filesize', def: 0 },
    { name: 'description', def: '' },
    { name: 'notes', def: '' },
    { name: 'locked', def: false },

    { name: 'created', type: 'dateTime', readonly: true },
    { name: 'updated', type: 'dateTime', readonly: true },

    { name: 'client_viewable', def: false },
    { name: 'client_uploadable', def: false },
    { name: 'publishable', def: false },
    { name: 'client_inherit', def: false },
    { name: 'apply_to_children', def: false },

    { name: 'template', def: false },
    { name: 'breadcrumbs', def: false, readonly: true },

    { name: 'selected', def: false, readonly: true },
    { name: 'progress', def: 0, readonly: true },
    { name: 'lockOld', def: true },
    { name: 'unique', def: false },

    { name: 'qc', def: false },
    { name: 'public', def: false },
    { name: 'has_preview', readonly: true },

  ];

  url = 'files/nodes/';

  constructor(id) {
    super(id);
    this.check();
    this.clear();
  }

  // eslint-disable-next-line class-methods-use-this
  savePreDataProcessing(data, additionalData = {}) {
    const mungedData = { ...data };
    Object.keys(additionalData).forEach((item) => {
      if (additionalData[item] instanceof File) {
        mungedData.mimetype = additionalData[item].type;
        mungedData.filename = additionalData[item].name;
      }
    });
    return { data: mungedData, additionalData };
  }

  canDelete() {
    const today = new Date();

    if (SlateAuth.permissions.files_can_delete_attachments) { return true; }

    const diffMs = (today - this.created);
    const diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000);
    if (SlateAuth.user.contact_id === this.core.createdby && diffMins < 720) { return true; }

    return false;
  }

  getDownloadUrl(ver) {
    let url = 'files/download/';
    if (this.apiBaseUrl.endsWith('/') && url.startsWith('/')) { url = url.slice(1); }
    if (!this.apiBaseUrl.endsWith('/') && !url.startsWith('/')) { url = `/${url}`; }

    if (!ver) { return `${this.apiBaseUrl}files/download/${this.id}/`; }
    return `${this.apiBaseUrl}files/download/${this.id}/ver/${ver}/`;
  }

  getFileSize() {
    let size = this.filesize;
    let unit = 'B';
    if (size === null || Number.isNaN(size)) {
      return '';
    }
    if (size > 1024) {
      size /= 1024;
      unit = 'K';
    }
    if (size > 1024) {
      size /= 1024;
      unit = 'M';
    }
    if (size > 1024) {
      size /= 1024;
      unit = 'G';
    }
    return size.toFixed(2).toString() + unit;
  }
}
