import BaseApiDetails from '../baseApiDetailsClass';

export default class DiscussionMsgDetails extends BaseApiDetails {
  url = 'discussions/msgs/';
  fields = [
    { name: 'id', def: null, readonly: true },

    { name: 'createdby', readonly: true },
    { name: 'createdby_name', readonly: true },
    { name: 'createdby_slug', readonly: true },

    { name: 'created', type: 'dateTime', readonly: true },

    { name: 'message' },
    { name: 'discussion' },

  ];

  constructor(id) {
    super(id);
    this.check();
    this.clear();
  }
}
