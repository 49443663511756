import axios from 'axios';
import { get } from 'lodash';

import SlateAuth from '@/api/auth/auth';


export default class BaseApiList {
  // PUBLIC required fields
  DetailModel;
  url;

  // PUBLIC optional fields/settings
  anonymous = false;
  appendOnly = false;

  // Hooks to override
  beforeListFetch() { return this; }
  beforeListUpdate() { return this; }
  postListUpdate() { return this; }

  // PRIVATE fields, don't override unless you know what you're doing
  listPayload = 'data.payload';
  apiBaseUrl = process.env.API_URL ? process.env.API_URL : '/api/v1/';
  filters = {};
  list = [];
  permissions = [];
  total = 0;
  cancelToken = null;

  constructor(filters) {
    // ensure that the two url parts can be concatenated together without issue
    if (typeof this.url === 'string') {
      if (this.apiBaseUrl.endsWith('/') && this.url.startsWith('/')) {
        this.url = this.url.slice(1);
      }
      if (!this.apiBaseUrl.endsWith('/') && !this.url.startsWith('/')) {
        this.url = `/${this.url}`;
      }
    }

    // if the filters are an object, set them
    if (filters instanceof Object && !(filters instanceof Array)) {
      this.filters = filters;
    }
  }

  clear() {
    // reset our private members
    this.filters = {};
    this.list = [];
    this.permissions = [];
    this.total = 0;
    this.cancelToken = null;
  }

  isAuthed() {
    if (this.anonymous) { return true; }
    if (SlateAuth.token) { return true; }
    return false;
  }

  getList(filters) {
    return new Promise((resolve, reject) => {
      if (!this.isAuthed()) { return reject(new Error('Not logged in')); }
      if (typeof this.url === 'undefined') { return reject(new Error('model url not set')); }
      if (typeof this.DetailModel === 'undefined') { return reject(new Error('detail model not set')); }

      // filters must be an object, if it's valid, set the filters member
      if (filters instanceof Object && !(filters instanceof Array)) {
        if (this.filters instanceof Object && !(this.filters instanceof Array)) {
          this.filters = { ...this.filters, ...filters };
        } else {
          this.filters = filters;
        }
      }

      if (this.cancelToken) {
        this.cancelToken.cancel('Refretching List');
      }

      this.cancelToken = axios.CancelToken.source();

      this.beforeListFetch();

      axios.get(`${this.apiBaseUrl}${this.url}`, {
        params: this.filters,
        cancelToken: this.cancelToken.token,
      }).then((response) => {
        this.beforeListUpdate(response);
        let newList = [];
        // if we're in append only mode, we assign our list to newList
        // and since js will assign a ref, not copy, updating newList will
        // update our list also. Below, we avoid reassigning it.
        if (this.appendOnly) { newList = this.list; }

        get(response, this.listPayload).forEach((item) => {
          const itemModel = new this.DetailModel();
          itemModel.loadFromPayload(item);
          newList.push(itemModel);
        });

        if (!this.appendOnly) { this.list = newList; }

        if (response.data.totalItems) {
          this.totalItems = response.data.totalItems;
        } else {
          this.totalItems = response.data.payload.length;
        }

        this.postListUpdate(response);

        resolve(this);
      }).catch((error) => {
        if (axios.isCancel(error)) {
          error.cancelled = true;
        }
        return reject(error);
      });

      return this;
    });
  }
}
