<template>
  <div class="discussion-embedded">
    <slate-loading v-if="loading" />
    <discussion-box
      v-else
      :discussion="discussion"
      :messages="msgList.list"
      :entry="msgEntry"
      @post="post"
    />
  </div>
</template>

<script>
import SlateLoading from '@/components/_baseLoading.vue';
import Discussion from '@/api/discussions/discussion.details';
import DiscussionMsgList from '@/api/discussions/discussion.msg.list';
import DiscussionMsgDetails from '@/api/discussions/discussion.msg.details';

import DiscussionBox from './discussion.box.vue';

export default {
  name: 'slateDiscussionEmbedded',
  props: {
    parentType: String,
    parentFk: String,
    refresh: {
      type: Number,
      default: 30,
    },
  },
  components: {
    SlateLoading,
    DiscussionBox,
  },
  data: () => ({
    loading: true,
    posting: false,
    lastRetrieve: null,
    discussion: new Discussion(),
    msgList: new DiscussionMsgList(),
    msgEntry: new DiscussionMsgDetails(),
  }),
  watch: {
    parentFk: {
      handler: 'updateData',
      immediate: true,
    },
  },
  methods: {
    updateData() {
      if (this.parentType && this.parentFk) {
        const params = {
          parent_fk: this.parentFk,
          parent_type: this.parentType,
        };

        this.discussion.loadFromServer(params).then(() => {
          this.updateMessages();
        });
      }
    },
    updateMessages() {
      return this.msgList.getList({ discussion: this.discussion.id }).then(() => {
        this.loading = false;
        setTimeout(() => {
          this.updateMessages();
        }, this.refresh * 1000);
      });
    },
    async post() {
      this.posting = true;
      this.msgEntry.discussion = this.discussion.id;
      this.msgEntry.saveToServer().then(async () => {
        this.msgEntry = new DiscussionMsgDetails();
        this.msgEntry.discussion = this.discussion.id;

        await this.msgList.getList();
        this.posting = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.discussion-embedded {
  width: 100%;
  height: 100%;
  min-height: 12rem;
}
</style>
