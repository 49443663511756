<template>
  <div class="discussion-message-editor">
    <ckeditor v-model="entry.message" :editor="editor" :config="editorConfig"></ckeditor>
    <button
      class="button is-primary is-fullwidth"
      @click="post();"
      :disabled="posting || !entry.message"
    >
      <icon v-if="posting" icon="spinner fa-spin" />
      <icon v-else icon="comment-alt-lines" />
      <span v-if="posting">{{ $t('DISCUSSIONS.POSTING') }}</span>
      <span v-else>{{ $t('DISCUSSIONS.POST') }}</span>
    </button>
  </div>
</template>

<script>
import CKEditor from '@ckeditor/ckeditor5-vue';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {
  name: 'slateDiscussionEmbeddedMessages',
  components: {
    ckeditor: CKEditor.component,
  },
  props: {
    entry: Object,
    posting: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    message: '',
    editor: ClassicEditor,
    editorConfig: {
      toolbar: ['bold', 'italic', 'link', 'bulletedList', 'numberedList', '|', 'indent', 'outdent'],
    },
  }),
  methods: {
    post() {
      this.$emit('post', this.message);
    },
  },
};
</script>

<style lang="scss" scoped>
.discussion-message-editor {
  position: relative;
}

::v-deep .ck.ck-content {
  max-height: 12rem;
}
</style>
