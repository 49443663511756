import BaseApiList from '../baseApiListClass';
import FileNodeDetails from './node.details';

export default class FileNodeList extends BaseApiList {
  url = 'files/nodes/';
  DetailModel = FileNodeDetails;

  listPayload = 'data.payload.children';
  node = new FileNodeDetails();

  beforeListUpdate(response) {
    this.node = new FileNodeDetails();
    this.node.loadFromPayload(response.data.payload.node);

    this.breadcrumbs = response.data.payload.breadcrumbs;

    return this;
  }
}
