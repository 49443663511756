import BaseApiList from '../baseApiListClass';
import DiscussionMsgDetails from './discussion.msg.details';


export default class DiscussionMsgList extends BaseApiList {
  url = 'discussions/msgs/';
  DetailModel = DiscussionMsgDetails;

  appendOnly = true;

  beforeListUpdate() {
    const startDate = new Date();
    this.filters.start = startDate.toISOString();
    return this;
  }
}
