<template>
  <div class="discussion-message-list" ref="messageList" @scroll.passive="handleScroll">
    <div class="discussion-message" v-for="(message) in messages" :key="message.id">
      <article class="media">
        <figure class="media-left">
          <p class="image is-48x48">
            <img src="https://bulma.io/images/placeholders/128x128.png" />
          </p>
        </figure>
        <div class="media-content">
          <div class="content">
            <div class="discussion-message-header">
              <strong class="discussion-message-created-by">{{ message.createdby_name}}</strong>
              <small class="discussion-message-created-date">{{ formatTime(message.created) }}</small>
            </div>
            <div v-html="message.message" />
          </div>
        </div>
      </article>
    </div>
  </div>
</template>

<script>
import formatDistanceToNow from 'date-fns/formatDistanceToNow';

export default {
  name: 'slateDiscussionEmbeddedMessages',
  props: {
    messages: Array,
    default: [],
  },
  data: () => ({
    scrollBottom: true,
  }),
  methods: {
    formatTime(time) {
      return formatDistanceToNow(time, { addSuffix: true });
    },
    handleScroll() {
      const obj = this.$refs.messageList;
      if (!obj) { return false; }
      if (obj.scrollHeight - obj.offsetHeight - obj.scrollTop < 5) {
        this.scrollBottom = true;
      } else {
        this.scrollBottom = false;
      }
      return this.scrollBottom;
    },
  },
  watch: {
    messages: {
      immediate: true,
      handler: function handleMessageScroll() {
        if (!this.scrollBottom) { return false; }
        return this.$nextTick(() => {
          this.$refs.messageList.scrollTop = this.$refs.messageList.scrollHeight;
        });
      },
    },
  },

};
</script>

<style lang="scss" scoped>
.discussion-message-list {
  width: 100%;
  flex: 1 1 auto;
  max-height: 24rem;
  padding-right: 0.6rem;
  overflow-y: scroll;
}

.discussion-message {
  margin-bottom: 2.4rem;
  .discussion-message-header {
    display: flex;
    justify-content: flex-start;
    .discussion-message-created-by {
      flex: 1 1 auto;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin-right: 1.6rem;
    }
  }
}
</style>
